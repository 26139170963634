
import ClickOutside from 'vue-click-outside';
import query from '@/queries/quicksearch.graphql';
import categoryQuery from '@/queries/quicksearchCategory.graphql';

export default {
    directives: {
        ClickOutside,
    },
    props: {
        layout: {
            type: String,
            required: true,
        },
        max: {
            type: [Number, Array],
            required: true,
        },
    },
    data() {
        return {
            loading: false,
            timer: null,
            productSearchMinLength: 3,
            articleSearchMinLength: 1,
            articleSearchMaxLength: 100,
            debounceTimeout: 500,
            search: '',
            products: [],
            categories: [],
            articles: [],
        };
    },
    computed: {
        showFullPrice() {
            if (!this.$store.state.auth.loggedIn || !this.$store.state.auth.user) {
                return true;
            }

            return this.$store.state.auth.user.is_allowed_to_view_regular_price;
        },
    },
    watch: {
        $route() {
            this.products = [];
            this.search = '';
        },
    },
    methods: {
        processInput() {
            if (this.timer !== null) {
                clearTimeout(this.timer);
            }
            this.timer = setTimeout(() => this.processSearch(), this.debounceTimeout);
        },
        async processSearch() {
            this.timer = null;
            this.products = [];
            this.categories = [];
            await this.loadResults();
        },
        async loadResults() {
            this.loading = true;
            this.products = [];
            this.categories = [];
            try {
                if (this.search.length >= this.productSearchMinLength) {
                    const {
                        data: {
                            products: { items },
                        },
                    } = await this.$apollo.query({
                        query,
                        variables: { input: this.search, pageSize: this.max },
                        queryDeduplication: false,
                    });
                    this.products = items;
                }

                if (
                    this.search.length >= this.$store.state.auth.categorySearchMinLength &&
                    this.search.length <= this.$store.state.auth.categorySearchMaxLength
                ) {
                    const {
                        data: { categoryList },
                    } = await this.$apollo.query({
                        query: categoryQuery,
                        variables: { input: this.search, pageSize: this.max },
                        queryDeduplication: false,
                    });
                    const pageSize = 6;
                    this.categories = categoryList.slice(0, pageSize);
                }

                if (this.search.length >= this.articleSearchMinLength && this.search.length <= this.articleSearchMaxLength) {
                    const articleSearch = await this.$strapi.getEntries({
                        contentType: 'blog-articles',
                        sort: 'publishedAt:desc',
                        filters: {
                            title: {
                                $containsi: this.search,
                            },
                        },
                    });

                    if (articleSearch.data && articleSearch.data.length) {
                        this.articles = articleSearch.data;
                    }
                }
            } finally {
                this.loading = false;
            }
        },
        onSubmit() {
            this.$root.$emit('quicksearch', this.search);
            this.$router.push(this.localeRoute({ name: 'search', query: { q: this.search } }));
        },
        onClose(event) {
            if (!event.target.dataset.notclose) {
                this.products = [];
                this.search = '';
            }
        },
        onCloseEsc() {
            this.products = [];
            this.search = '';
        },
        brand(product) {
            if (product) {
                try {
                    const value = this.product.custom_attributes.find((attribute) => attribute.attribute_metadata.code === 'brand');

                    return value?.selected_attribute_options?.attribute_option[0].label;
                } catch (e) {}
            }

            return '';
        },
        minimalGroupedPrice(product) {
            const prices = product.items
                .filter((item) => item.product?.price_range?.minimum_price?.final_price?.value)
                .map((item) => {
                    return {
                        final: item.product.price_range.minimum_price.final_price.value,
                        regular: item.product.price_range.minimum_price.regular_price.value,
                    };
                })
                .sort((priceA, priceB) => priceA.final - priceB.final);

            const [cheapestProduct] = prices;
            cheapestProduct.hasDiscount = cheapestProduct.final !== cheapestProduct.regular;

            return cheapestProduct;
        },
    },
};
